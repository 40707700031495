module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"markdownCaptions":false,"maxWidth":590,"quality":90,"showCaptions":true,"linkImagesToOriginal":true,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-103034213-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicholas Jamieson's personal blog","short_name":"ncjamieson","start_url":"/","background_color":"#ffffff","theme_color":"#03a9f4","display":"browser","icon":"content/assets/ncjamieson.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ed057b7eb8c9db37ff07a4e6820af3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","attributes":{"boost":15}},{"name":"description","attributes":{"boost":5}},{"name":"categories","attributes":{"boost":10}},{"name":"keywords","attributes":{"boost":10}},{"name":"content"},{"name":"slug","store":true}],"resolvers":{"MarkdownRemark":{}},"filename":"search-index.json"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
